<template>
  <v-expansion-panel>
    <!-- header -->
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <!-- Enable Custom header -->
        <slot name="header" v-bind:open="open" v-if="hasSlot('header')"></slot>

        <!-- details banner as header-->
        <details-banner-layout v-else>
          <template v-slot:avatar>
            <v-icon>{{ iconName }}</v-icon>
          </template>
          <template v-slot:default>
            <slot name="default" v-bind:open="open"></slot>
          </template>
        </details-banner-layout>
      </template>
    </v-expansion-panel-header>

    <!-- content -->
    <v-expansion-panel-content>
      <slot name="content"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
// mixins
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "ExpansionPanelLayout",
  mixins: [slottableMixin],
  components: {
    DetailsBannerLayout: () =>
      import("@/components/shared/core/banners/DetailsBannerLayout")
  },
  data() {
    return {
      isOpen: false
    };
  },
  props: {
    iconName: {
      type: String,
      default: undefined
    }
  }
};
</script>
